/* eslint-disable no-var, eqeqeq, no-useless-escape,func-names,prefer-const,no-shadow, prefer-destructuring, prefer-template, array-callback-return  */
/* eslint-disable react/destructuring-assignment,consistent-return */

import React, {  useState, useEffect, useRef } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {ClickAwayListener, Menu, MenuItem, FormControl, InputLabel, Select, FormGroup, FormControlLabel, Checkbox, ownerDocument, Collapse, Container, FormLabel, RadioGroup, Radio } from '@material-ui/core';
import styles from './View.Style'
import VideoForm from 'components_newui/new_ui_video_form/View'
import NxtDatePicker from 'components/nxt_date_picker/View'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import TextInputOneLine from '../../components_newui/TextInputOneLine/View'
import TextInputMultiLine from '../../components_newui/TextInputMultiLine/View'
import ImageUploader from '../../components_newui/new_ui_image_uploader/View'
// import Challenge_card from '../../components_newui/challenge_card/View'
import FileUploader from 'components_newui/new_ui_file_uploader/View'
import Button from './components/Button'
import RadioSelectorOrg from '../../components/radio_selector/View'
import RadioSelector from './components/radio_selector/View'
import IdeaPreview from 'components_newui/idea_summary_preview/View'
import i18n from 'support/i18n'
import NavigationService from 'support/navigation/NavigationService'
import * as pages from 'common_constants/Pages'
// import { id } from 'date-fns/locale';
import TextEditor from 'components_newui/text_editor/View'
import NxtCheckbox from 'components/nxt_checkbox/View'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import navigation from '../../support/navigation';
import { id } from 'date-fns/locale';



const FileUploaderFn = (props)=>{
  const {onDocumentUpload,documentIdeaKey,match,isDocumentLoading,documents,csrf,isAdmin,isInnovator,isOwner,localAdmin,localInnovator} = props
  const handleDocumentUpload = (file) =>{
      onDocumentUpload(documentIdeaKey,file)
  }

  const handleDeleteDocument = (documentId) =>{
      const { onDocumentDelete } = props
      const id = match && match.params && match.params.id ? match.params.id : undefined
      onDocumentDelete(id, documentId)
  }
      return(
          <FileUploader
            dropText="Browse file"
            isDocumentLoading={isDocumentLoading}
            onFileSelect={handleDocumentUpload}
            documentfiles={documents}
            handleDeleteDocument={handleDeleteDocument}
            csrfToken={csrf}
            canDelete={isAdmin || isInnovator || isOwner || localAdmin || localInnovator}
        />
      )
  }




const PostIdea = props => {

    const descriptionRef = useRef()
    const {
        isAnonymousIdeasMandatory,
        isAnonymousIdeas,
        isTypesActive,
        isDocumentLoading,
        creatingIdea,
        isLoading,
        navigateToChallenge,
        createDataIdeaAll,
        classes,
        domainName,
        match,
        categories,
        challengesAll,
        types,
        externalVideoSrc,
        organizationName,
        onUploadVideo,
        onDeleteVideo,
        onCancel,
        goals,
        fileVideo,
        onRemoveFileVideo,
        navigateToAllChallenges,
        onFileSelect,
        documents,
        isDraft,
        submitIdea,
        submitDraft,
        onSelectImage,
        initialImages,
        imageUploadError,
        csrf,
        imagesLength,
        organizationsName,
        organizationHelpText,
        organizations = [],
        isOrganizationsActive,
        organization,
        //organizationError,
        isFileVideoLoading,
        isAdmin , isInnovator , isOwner , localAdmin , localInnovator,documentIdeaKey,onDocumentUpload,
    } = props


    const {
        isTitleAvailable,
        description,
        endDate,
        challenge,
        type,
        goalValues,
        implementEstimatedHours,
        implementEstimatedQuantity,
        implementEstimatedTime,
        implementEstimatedTimeType,
        pilotEstimatedHours,
        pilotEstimatedQuantity,
        pilotEstimatedTime,
        pilotEstimatedTimeType,
        economicBenefits,
        otherBenefits,
        isAnonymous,
        isPrivate,
        checkVideoState,
        fileVideoId,
        onVideoEncodingError,
        hasVideoError,
        hasLoaded,
        isGlobal,
        isServiceLineActivated,
        ideaChallengeID
      } = props

    // console.log("match--->",match,props)
      const challengeLoadId = match && match.params && match.params.challengeId
      ? match.params.challengeId
      : ideaChallengeID
    const [expireDateValue,setexpireDateValue]= useState(endDate)
    const [IsAnonymous,setIsAnonymous]= useState(isAnonymous)
    
    const [challengeUrlId,setChallengeUrlId] = useState(challengeLoadId) 
    const [ideaUrlId,setIdeaUrlId]=useState(null)
    const [ideaTitle, setIdeaTitle] = useState('')
    const [validIdea, setValidIdea] = useState(true)
    const [textareaDescription,setTextareaDescription] = useState('')
    const [problemDescription, setProblemDescription] = useState('')
    const [whyNeeded, setWhyNeeded] = useState('')
    const [whatUnique, setWhatUnique] = useState('')
    const [similarExisting, setSimilarExisting] = useState('')
    const [planDescription, setPlanDescription] = useState('')
    const [benefitsDescription, setBenefitsDescription] = useState('')
    const [category, setCategory] = useState(null)
    const [taggedChallenges, setTaggedChallenges] = useState([])
    const [problemvalidIdeaDescription, setproblemvalidIdeaDescription] = useState(true)
    const [textareaValidIdeaDescription , setTextareaValidIdeaDescription] = useState(true)
    const [whyneededvalidIdeaDescription, setwhyneededvalidIdeaDescription] = useState(true)
    const [uniquevalidIdeaDescription, setuniquevalidIdeaDescription] = useState(true)
    const [similarvalidIdeaDescription, setsimilarvalidIdeaDescription] = useState(true)
    const [planvalidIdeaDescription, setplanvalidIdeaDescription] = useState(true)
    const [benefitvalidIdeaDescription, setbenefitvalidIdeaDescription] = useState(true)

    const [strategicPriorities, setStrategicPriorities] = useState([])
    const [organisation, setorganisation] = useState('')
    const [organizationError, setOrganizationError] = useState('')
    // const [orgFoldername,setOrgFoldername] = useState('')
    var orgFoldername;
    const [showPreview, setShowPreview] = useState(false)
    ///////
    const [stimages, stsetImages] = useState([]) //DELETE THIS and move to index for dispatch/state

    const [externalVideo, setExternalVideo] = useState('')
    //////
    const [titleError, settitleError] = useState("")
    const [imageError, setimageError] = useState("")
    const [descriptionError, setdescriptionError] = useState("")
    const [categoryError, setCategoryError] = useState("")

    const [isChecked, setIsChecked] = useState(false);
    const [isSelectID,setId] = useState('')
    const minDate = new Date()
    const editorConfig = {
        toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList'],
      }
    // const handleChange = (event) => {
    //     console.log('evt',event.target)
    // //   setIsChecked(event.target.checked);
    //     setId(event.target.value)
    // };

    // console.log("endDate before", endDate,expireDateValue)
    // console.log("endDate after", expireDateValue)
      
    //edit post
    const isEditPage =  NavigationService.getRoutes()[pages.EDIT_IDEA].path === match.path
    // console.log('post edit',isEditPage)

useEffect(()=>{
    const id = match && match.params && match.params.id ? match.params.id : undefined
    setIdeaUrlId(id)
},[])

    useEffect(() => {
        // console.log('enter st=?',strategicPriorities)
    }, [strategicPriorities]) 


// console.log("createDataAll",createDataIdeaAll,createDataIdeaAll.type,ideaTitle,stimages,category)
    // useEffect(()=>{ if(createDataIdeaAll) console.log("updated",createDataIdeaAll)},[createDataIdeaAll])
    let typenum = createDataIdeaAll.type.id
    // let typePrev = ' ' + typenum
    let orgPrev =  createDataIdeaAll.organization.id

    let goalPrev = createDataIdeaAll.goals
    let objkeyid = Object.keys(goalPrev)    
    let objArrValue = objkeyid.map(i=>i)

    let ideaDescription = createDataIdeaAll.description
    // let htmltoplaintext =  convertToPlain(ideaDescription)
    // console.log('createDataIdeaAll ',goalPrev,objkeyid[0],objArrValue)
    // console.log('before time load =>', textareaDescription)
useEffect(()=>{
    if(isEditPage){    
        setIdeaTitle(createDataIdeaAll.title)
        stsetImages(createDataIdeaAll.images)
        setTextareaDescription(ideaDescription)
        setExternalVideo(externalVideoSrc)
        setCategory(typenum)   
        setorganisation(orgPrev)
        setStrategicPriorities(objArrValue)
        setTaggedChallenges([createDataIdeaAll.challenge])
        // setexpireDateValue(createDataIdeaAll.endDate)
    }
},[])
// console.log('desc 1st time load =>', objkeyid.map(i=>i))
    // Maybe have page hierarchy come from state????? at least get navigate functions from state
    const pageHierarchy = [
        {string: "Home", navigate: ()=>navigation.navigateToExplore()}, 
        { string: isEditPage ? "Edit idea" : "Post an idea", navigate: () => {} }
    ]

    useEffect(() => {
        const {
            getIdea,
            resetForm,
            getGoals,
            getChallenges,
            getOrganizations,
            getTypes,
            getServiceLineStatus
        } = props
        const id = match && match.params && match.params.id ? match.params.id : undefined
        if (id) {
            getIdea(id)
        } else {
            resetForm()
        }
        getGoals()
        getChallenges()
        getOrganizations()
        getTypes()
        getServiceLineStatus()
    }, [])

    const validateData = () => {
        // Add data validation here
        let isValid = true
        // let hypenRegex = new RegExp("\\p{L}\\p{N}0-9\\_.:,;¿?¡!\"'()\\[\\]/%&€+") "[\\p{L}\\p{N}0-9\\_.:,;¿?¡!\"'()\\[\\]/%&€ ]+"
        const hypenRegex =  new RegExp(/[-!$%^*+|~=`{}\[\]:\/;<>?.@#]/)  // eslint-disable-line prefer-regex-literals
        // console.log("test input hypenRegex.test(ideaTitle)===>",hypenRegex.test(ideaTitle))
        if (!ideaTitle || hypenRegex.test(ideaTitle)) {
            isValid = false;
            settitleError(i18n.t('IDEA.TITLE_ERROR'))
            setValidIdea(false)
        }
        if (initialImages.length === 0 ) {
            isValid = false;
            setimageError(i18n.t('IDEA.IMAGE_ERROR'))
        }
        // if (externalVideoError || (video && !checkYoutubeUrl(video))) {
        //   newState = { ...newState, invalidYoutubeUrlError: i18n.t('IDEA.EXTERNAL_VIDEO_ERROR') }
        //   // reference = this.externalVideoRef
        // }
        // console.log("idea desc hint: " + i18n.t('IDEA.DESC_HINT_VOIS'))
        // console.log("idea desc hint: " + textareaDescription)
        // console.log("idea desc hint: " + textareaDescription === i18n.t('IDEA.DESC_HINT_VOIS'))
        if(textareaDescription === '' || textareaDescription === undefined || textareaDescription === i18n.t('IDEA.DESC_HINT_VOIS')){
            // console.log("idea desc hint: error")
            isValid= false;
            setdescriptionError(i18n.t('IDEA.DESC_ERROR'))
            setTextareaValidIdeaDescription(false)
        }
       
        if (isTypesActive && category === "") {
            isValid = false;
            setCategoryError(i18n.t('IDEA.TYPE_MANDATORY_ERROR'))

        }
        // console.log("validation: isOrganizationsActive: " + isOrganizationsActive)
        // console.log("validation: organisation: " + organisation)
        // console.log("validation: organisationError: " + organizationError)

        if (isOrganizationsActive && (organisation === '' || organisation === undefined )){
            isValid = false;
            setOrganizationError(i18n.t('IDEA.ORGANIZATION_MANDATORY_ERROR'))
            // console.log("validation: organisation error: " + organizationError)
        }
        return isValid
    }


    let fillforms = {
      id: match && match.params && match.params.id ? match.params.id : undefined,
      title: ideaTitle,
      description: textareaDescription,
      images: initialImages,
      video: externalVideo,
      endDate: expireDateValue,
      isPrivate: false,
      // challenges
      challenge: challengeUrlId,
      // organizations
      organization: organisation,
      type: category,
      // goalValues:100,
      goalValues: strategicPriorities.reduce((a, v) => ({ ...a, [v]: 100 }), {}),
      isGlobal: true,
      isServiceLineActivated: false,
      isAnonymous: isAnonymousIdeasMandatory || IsAnonymous,
      
  }

  const handleIsAnonymous = ()=>{
    setIsAnonymous( !IsAnonymous )
  }

    const handleSubmitIdea = () => {
        if(!isDraft)
        submitIdea(fillforms)
    }
    const handleStateChange = (handlechange, e) => {
        // console.log('deee',e)
        setorganisation(e)
    }
    const handleStateChangeExpireDatevalue = (handlechange, e) => {
        console.log('handleStateChangeExpireDatevalue======',e, handlechange)
        setexpireDateValue(e)
    }

    // console.log("sddd", strategicPriorities.reduce((a, v) => ({ ...a, [v]: 100 }), {}))

    // const findkey = (value) =>{
    //    return Object.keys(obj).find(key => obj[key].includes(value))
    // }
    // console.log("goal=====>",goalValues)

    const handleSubmitDraft = (e) => {
        // console.log('one click',e)
        // console.log("submit-draft")
        // const { submitDraft } = this.props
        // console.log('coll-des',collectDescription)
        const formdata = fillforms
            if (validateData()) {
                submitDraft(formdata)
            }
    }

    const handleEditSaveIdeaClick = () =>{
        const {  editIdea } = props
        // const id = match && match.params && match.params.id ? match.params.id : undefined
            if (validateData()) {                
                    editIdea(ideaUrlId,fillforms)
                
            }
    }

    const handleReSubmitClick = () =>{
        const {resubmitIdea,editIdea}=props
        // const id = match && match.params && match.params.id ? match.params.id : undefined
        // console.log('resubmit')
        if (validateData()) {
        
                editIdea(ideaUrlId,fillforms)
                resubmitIdea(ideaUrlId,true,fillforms)
            
        }
    }


    // const handleDocumentUpload = (file) => {
    //     const { onDocumentUpload, videoIdeaId, documents } = props
    //     const id = match && match.params && match.params.id ? match.params.id : undefined
    //     if (documents.length > 0 && documents[0].key) onDocumentUpload(documents[0].key, file)
    //     else if (videoIdeaId) onDocumentUpload(videoIdeaId, file)
    //     else onDocumentUpload(id, file)
    // }

    // const handleDeleteDocument = (documentId) =>{
    //     const { onDocumentDelete } = props
    //     const id = match && match.params && match.params.id ? match.params.id : undefined
    //     onDocumentDelete(id, documentId)
    //   }

 

    const orgFn = () => {
        let foldername = organizations.filter(item => item.organizations.find(i => i.id == organisation))[0]
        // console.log("folder ===>", foldername && foldername.name)
        let folderNameData = foldername && foldername.name
        let ordData = organizations.map(org => org.organizations.find(item => item.id == Number(organisation)))
        var filtered = ordData.filter(function (el) {
            return el != null;
        });
        // console.log('sss=>', ordData, filtered[0].name)
        let folderInsidename = filtered && filtered[0] && filtered[0].name
        return folderNameData + ' - ' + folderInsidename

    }

const handleChallengeChangeRadio = (value) => {
    // console.log(' value =====>',value,'/n challengeUrlId ==>'  , challengeUrlId)
  
    const { challengesAll } = props
    let innerChallengeId = value
    let isGlobalLocal = false
    if (challengeUrlId === value) {
      innerChallengeId = ''
    }
    // console.log("innerChallengeId =====>",innerChallengeId)
    challengesAll.map(item => {
      if (item.id === value) {
        // isGlobalLocal = item.isGlobal ? true : false
        isGlobalLocal = !!item.isGlobal
        return null
      }
    })
    setChallengeUrlId(innerChallengeId)
  }

  const handleImageupload = image => onSelectImage(ideaUrlId, image)
  const handleDeleteImage = (newImages, _imageName, imageToDelete)  =>{
    const { onDeleteImage } = props
    onDeleteImage(ideaUrlId, newImages, imageToDelete)
  } 



// console.log("imagesLengthimagesLengthimagesLength========>",isFileVideoLoading)

    return (
      <>
        <Breadcrumbs crumbs={pageHierarchy} />
        <Container>
          <main className={classes.background}>
            <div className={classes.content_container}>
              <div className={classes.upper_background}>
                <p className={classes.title}>{isEditPage ? 'Edit idea' : 'Post an idea'} </p>
                { /* <p className={classes.invested_ideas_number}>Draft ideas ({draft_ideas.length})</p> */ }
              </div>
              <div className={classes.lower_background}>
                <div className={classes.create_idea_box}>
                  <div className={classes.create_title}>
                    {' '}
                    {isEditPage
                      ? "Let's Edit an innovative idea"
                      : "Let's create an innovative idea"}{' '}
                  </div>
                  <p className={classes.idea_title_in}>
                    Idea title <span style={{ color: '#E60000' }}>*</span>
                  </p>
                  {/* {console.log('ideaa-', ideaUrlId, ideaUrlId !== undefined)} */}
                  <TextInputOneLine
                    canEditTitle={ideaUrlId !== undefined}
                    width={780}
                    placeholder='Idea title (50 characters max)'
                    char_limit={50}
                    value={ideaTitle}
                    valid={validIdea}
                    handleChange={event => {
                      const value = event.target.value
                      setIdeaTitle(value)
                      setValidIdea(!!value)
                      settitleError('')
                    }}
                  />
                  <span className={classes.error_message}>{titleError}</span>
                  <div className={classes.idea_title_char_limit}>
                    <span className={classes.idea_title_chars}>{ideaTitle.length}</span>/50
                  </div>

                  <div className={classes.secondary_header}>
                    Upload image/video <sup style={{ color: '#E60000', fontSize: '18px' }}>*</sup>
                  </div>
                  <div className={classes.smaller_subtitle}>At least one is mandatory</div>
                  <div style={{ display: 'flex', alignItems: 'top', paddingBottom: '50px' }}>
                    <ImageUploader
                      images={initialImages}
                      subtitle="Browse image (Max 5)"
                      imageUploadError={imageUploadError}
                      onSelectImage={handleImageupload}
                      deleteImage={handleDeleteImage}
                      UploadLimit={5}
                      imagesLength={imagesLength}
                    />

                    <div className={classes.vertical_line} />
                    <VideoForm
                      onExternalVideoError={e => {}}
                      externalVideoSrc={externalVideo}
                      fileVideoSrc={fileVideo}
                      onChangeExternalVideo={src => setExternalVideo(src)}
                      onRemoveFileVideo={onRemoveFileVideo}
                      isVideoLoading={false}
                      isVideoReady={false}
                      isVideoRemoving={false}
                      hasVideoError={false}
                      onUploadVideo={onUploadVideo}
                    />
                  </div>
                  <span className={classes.error_message}>{imageError}</span>
                  <div className={classes.horizontal_line} />

                  <div className={classes.secondary_header} style={{ marginBottom: '8px' }}>
                    Idea description <span style={{ color: '#E60000', font: '18px' }}>*</span>
                  </div>
                  <div style={{ marginBottom: '8px', fontSize: '14px', fontStyle: 'italic' }}>
                    Please make sure to answer the questions below
                  </div>
                  <div className={classes.singleTextAreaBox} style={{ marginLeft: '-12px' }}>
                    <TextEditor
                      value={isEditPage ? description : textareaDescription}
                      onChange={val => {
                        // console.log(textareaDescription)
                        setTextareaDescription(val)
                      }}
                      showDescription
                      domainName={domainName}
                    />
                    {textareaValidIdeaDescription ? (
                      ''
                    ) : (
                      <span style={{ marginLeft: '12px' }} className={classes.error_message}>
                        {descriptionError}
                      </span>
                    )}
                  </div>



                  {isTypesActive && types.length > 0 && (
                    <>
                      <div className={classes.horizontal_line} />
                  <div className={classes.secondary_header} style={{ marginBottom: '24px' }}>
                    Choose idea category <sup style={{ color: '#E60000', fontSize: '18px' }}>*</sup>
                  </div>

                 
                    <FormControl>
                      <Select
                        value={category}
                        onChange={e => {
                          setCategory(e.target.value)
                          setCategoryError('')
                        }}
                        className={classes.category_select}
                        label="Choose category"
                        defaultValue="Choose"
                        disableUnderline
                        displayEmpty
                        renderValue={() =>
                          category
                            ? types.filter(typeItem => parseInt(typeItem.id,10) === parseInt(category,10))[0].name
                            : 'Choose category'
                        }
                      >
                        {types.map(typeItem => (
                          <MenuItem key={typeItem.id} value={typeItem.id}>
                            {typeItem.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>)}
                  <div>
                    <span className={classes.error_message}>{categoryError}</span>
                  </div>
                  

                  

      {goals && goals.length > 0 && ( <>
                    <div className={classes.horizontal_line} style={{ marginTop: '56px' }} />
                  <div className={classes.secondary_header} style={{ marginBottom: '24px' }}>
                    Choose strategic priorities
                  </div>
                  <div className={classes.smaller_subtitle}>
                    Craft your idea as per following organisational goals
                  </div>

                  {/* {console.log('go strategicPriorities==>   ', strategicPriorities , strategicPriorities.map(i=>i) )} */}
                  {/* {console.log('goals==>', goals.filter(item=>item.id) )} */}
                  {/* {console.log("goal maps=>", goals.map(goal => console.log("goalid map ->",goal.id , strategicPriorities.map(i => i) ) ))} */}
                  {/* {console.log("goal true =======>",  goals.filter(goal => strategicPriorities.includes(goal.id)).map(item=>item.name) )} */}

                  
                    <FormGroup className={classes.checkbox_formgroup}>
                      {goals.map(goal => (
                        <div key={goal.id} className={classes.container}>
                            <FormControlLabel
                                key={goal.name}
                                control={
                                    <Checkbox 
                                        className={classes.checkboxRoot}
                                        color="default"
                                        icon={<span className={classes.radioNotChecked} />}
                                        checkedIcon={<span className="icon-approved" />}
                                        checked={strategicPriorities.filter(i => i === goal.id).length > 0}
                                        onChange={e => e.target.checked ?
                                            setStrategicPriorities(prev => [...prev, goal.id])
                                            : setStrategicPriorities(prev => prev.filter(g => g !== goal.id))}
                                    />
                                }
                                label={goal.name}
                            />
                        </div>
                      ))}
                    </FormGroup>
                 </> )}

                  {/* {goals &&
                                goals.length > 4 ?
                                <div style={{ display: 'flex' }}>
                                    <FormGroup className={classes.checkbox_formgroup}>
                                        {goals.slice(0, Math.ceil(goals.length / 2)).map(goal => (
                                            <FormControlLabel
                                                key={goal.name}
                                                control={
                                                    <Checkbox checked={strategicPriorities.filter(id => id === goal.id).length > 0}
                                                        onChange={e => e.target.checked ?
                                                            setStrategicPriorities(prev => [...prev, goal.id])
                                                            : setStrategicPriorities(prev => prev.filter(g => g !== goal.id))}
                                                    />}
                                                label={goal.name}
                                            />
                                        ))}
                                    </FormGroup>
                                    <FormGroup className={classes.checkbox_formgroup}>
                                        {goals.slice(Math.ceil(goals.length / 2)).map(goal => (
                                            <FormControlLabel
                                                key={goal.name}
                                                className={classes.formLabel}
                                                control={
                                                    <NxtCheckbox checked={strategicPriorities.filter(id => id === goal.id).length > 0}
                                                        onChange={e => e.target.checked ?
                                                            setStrategicPriorities(prev => [...prev, goal.id])
                                                            : setStrategicPriorities(prev => prev.filter(g => g !== goal.id))}
                                                    />}
                                                label={goal.name}
                                            />
                                        ))}
                                    </FormGroup>
                                </div>
                                :
                                <FormGroup className={classes.checkbox_formgroup}>
                                    {goals.map(goal => (
                                        <FormControlLabel
                                            key={goal.name}
                                            control={
                                                <Checkbox checked={strategicPriorities.filter(id => id === goal.id).length > 0}
                                                    onChange={e => e.target.checked ?
                                                        setStrategicPriorities(prev => [...prev, goal.id])
                                                        : setStrategicPriorities(prev => prev.filter(g => g !== goal.id))}
                                                />}
                                            label={goal.name}
                                        />
                                    ))}
                                </FormGroup>
                            } */}

                  
                  {isOrganizationsActive && ( 
                    <>
                    <div className={classes.horizontal_line} style={{ marginTop: '32px' }} />
                    <div className={classes.section}>
                      <div className={classes.secondary_header} style={{ marginBottom: '24px' }}>
                        {`${organizationsName}`}{' '}
                        <sup style={{ color: '#E60000', fontSize: '18px' }}>*</sup>
                      </div>

                      <RadioSelectorOrg
                        items={organizations}
                        // helpText={organizationHelpText}
                        subItemsKey="organizations"
                        value={organisation}
                        onValueChange={e => {
                          handleStateChange('organization', e)
                        }}
                        // onValueChange={e=>setorganisation(e.target.value)}
                        error={organizationError}
                      />
                      {organizations.length === 0 && (
                        <h3 className={classes.noOrgsSpan}>{i18n.t('ORGANIZATION.NO_ACTIVE')}</h3>
                      )}
                      {organizationError === '' ? (
                        ''
                      ) : (
                        <span style={{ marginLeft: '12px' }} className={classes.error_message}>
                          {organizationError}
                        </span>
                      )}
                    </div>
                 </> )}

                 {!isPrivate && (<>
                  <div className={classes.horizontal_line} style={{ marginTop: '32px' }} />
                  <div className={classes.challengeWrpbox}>
                    <div className={classes.upper_background}>
                      <div className={classes.secondary_header} style={{ marginBottom: '24px' }}>
                        Tag to challenges
                      </div>
                      {/* <p onClick={navigateToAllChallenges} className={classes.view_all_challenges}>View all</p> */}
                    </div>
                    {/* {console.log("taggedChallenges=>",taggedChallenges,challengesAll)} */}

                   
                      <div className={classes.section}>
                        <RadioSelector
                          onValueChange={handleChallengeChangeRadio}
                          items={challengesAll}
                          linkName={i18n.t('EXPLORE_IDEA.VIEW_CHALLENGE')}
                          helpText={i18n.t('CHALLENGE.DESC')}
                          value={challengeUrlId}
                          navigateTo={navigateToChallenge}
                          error={null}
                          globalIdeaCheck={isGlobal}
                          checkedValue={challengeUrlId}
                          taggedIcon
                        />
                      </div>
                    
                  </div>
                  </>)}


                  <div className={classes.horizontal_line} style={{ marginTop: '32px' }} />
                  <div className={classes.secondary_header} style={{ marginBottom: '24px' }}>
                    Upload supporting documents
                  </div>


                  <FileUploaderFn {...props} ></FileUploaderFn>

                  { endDate && expireDateValue !== undefined && (
                    
                    <div className={classes.formItem}>
                      <div className={classes.secondary_header} style={{ marginBottom: '24px' }}>{i18n.t('IDEA.WHEN_ACTIVE')}</div>
                      <NxtDatePicker
                        className={classes.dateInputItem}
                        id="date-picker-from"
                        onDateChange={e=>handleStateChangeExpireDatevalue('expireDateValue',e)}
                        value={expireDateValue}
                        endIconName="icon-th"
                        minDate={minDate}
                      />
                    </div>
                  )}

                  {!isAnonymousIdeasMandatory && isAnonymousIdeas && (
                    <section className={classes.section}>
                       <div className={classes.secondary_header} >  {i18n.t('IDEA.ANONYMOUS')} </div>
                        <p>{i18n.t('IDEA.ANONYMOUS_SUB').replace('##BRAND_NAME##', '')}</p>
                          <FormControlLabel
                            className={classes.checkboxRoot}
                            control={<> 
                             {!IsAnonymous ? <span className={classes.radioNotChecked} /> 
                              : <span className="icon-approved" /> }
                              <input type="checkbox" checked={IsAnonymous} className={classes.hiddencheckbox} value={IsAnonymous} onChange={handleIsAnonymous}  />
                              </>} 
                            label={i18n.t('IDEA.ANONYMOUS_CHECK')}
                          />
                    </section>
                  )}


                  <div
                    className={classes.horizontal_line}
                    style={{ marginTop: '32px', marginBottom: '32px' }}
                  />
                  <div style={{ display: 'flex', paddingBottom: '48px' }}>
                    <Button text="Cancel" clickCallBack={e => navigation.navigateToExplore()} />

                    <div style={{ marginLeft: 'auto', display: 'flex' }}>
                      {isEditPage ? null : (
                        <Button
                          text="Save as draft"
                          disabled={isLoading}
                          clickCallBack={e => handleSubmitDraft(e)}
                        />
                      )}
                      <div style={{ marginLeft: '20px' }}>
                        <Button
                          width={177}
                          disabled={creatingIdea}
                          text="Preview and submit"
                          clickCallBack={e =>
                            validateData() ? setShowPreview(true) : setShowPreview(false)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                          
            {showPreview && (
              <>
                {/* <div className={classes.faded_out} /> */}

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ClickAwayListener onClickAway={() => setShowPreview(false)}>
                    <div className={classes.idea_preview1}>
                      <IdeaPreview
                        isLoading={creatingIdea}
                        isSubmitting={creatingIdea}
                        showPreview={showPreview}
                        setShowPreview={setShowPreview}
                        editIdea={ideaUrlId !== undefined}
                        handleCancleClick={() => setShowPreview(false)}
                        handleSubmitClick={e => handleSubmitIdea(e)}
                        handleEditSaveIdeaClick={e => handleEditSaveIdeaClick(e)}
                        handleReSubmitClick={e => handleReSubmitClick(e)}
                        handleIsDraft={isDraft}
                        isSavaButtonEnable={isAdmin || isInnovator || localAdmin || localInnovator}
                        title={ideaTitle}
                        images={initialImages}
                        video={externalVideo}
                        textareaDescription={textareaDescription}
                        category={
                          category
                            ? types.filter(type => parseInt(type.id,10) === parseInt(category,10))
                            : null
                        }
                        organisation={orgFn()}
                        goals={goals}
                        strategicPriorities={ goals.filter(goal => strategicPriorities.includes(goal.id)).map(item=>item.name)}
                        challenges={
                          challengesAll.length > 0
                            ? challengesAll.filter(item => item.id === challengeUrlId)
                            : []
                        }
                        supportingDocs={documents}
                        csrfToken={csrf}
                        isOrganizationsActive={isOrganizationsActive}
                      />
                    </div>
                  </ClickAwayListener>
                </div>
              </>
            )}
          </main>
        </Container>
      </>
    )
}
export default withStyles(styles)(PostIdea)